export const COURSES = [
    {
        name: 'Web Development',
        link: '#'
    },
    {
        name: 'Mobile Development',
        link: '#'
    },
    {
        name: 'UI/UX Design',
        link: '#'
        
    },
    {
        name: 'Project Manager',
        link: '#'
    }
]

export const SOCIALMEDIA = [
    {
        name: 'Facebook',
        link: '#'
    },
    {
        name: 'Twitter',
        link: '#'
    },
    {
        name: 'Instagram',
        link: '#'
    },
    {
        name: 'Tiktok',
        link: '#'
    }

]

export const CONTACT = [
    {
        name: 'Owlearning@gmail.com',
        link: '#'
    },
    {
        name: '+62 856 7685 0990',
        link: '#'
    },
    
]